<template>
  <div
    class="screen-share-player-parent"
    :class="{
        'is-chat-open': isChatRunning,
        'is-hidden': !isScreenSharePlayerShowing,
        'is-mini': !isYouTubePlayerFull,
        'is-obs': obsStudioPluginVersion,
        'is-playing': isScreensharePlaying,
      }"
  >
    <!-- PLAYER OUTER -->
    <div class="player-outer">

      <!-- PLAYER INNER -->
      <div class="player-inner">

        <!-- WALLPAPER -->
        <WallpaperIcons
          class="wallpaper-icons"
          :isPlaying="isScreenSharePlayerShowing"
        />

        <!-- VIDEO OUTER -->
        <div class="video-outer">
          <video
            class="video"
            ref="videoEl"
          >
          </video>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import Peer from "peerjs";
  import WallpaperIcons from "../../Backdrops/WallpaperIcons/WallpaperIcons.vue";

  export default {
    components: {
      WallpaperIcons,
    },
    data() {
      return {
        isScreensharePlaying: false,
        obsStudioPluginVersion: null,
        peerInstance: null,
        videoEl: null,
      };
    },
    computed: {
      ...mapGetters({
        getActiveTask: "tasks/getActiveTask",
        getClientDeviceId: "user/getClientDeviceId",
        getDisplayName: "user/getDisplayName",
      }),
      clientIdFormatted() {
        return this.getClientDeviceId?.replace(/[_-]/g, "");
      },
      isChatRunning() {
        return !!this.getActiveTask("chat", "Run");
      },
      isScreenSharePlayerShowing() {
        return !!this.getActiveTask("screenSharePlayer", "Show");
      },
      isYouTubePlayerFull() {
        // NOT IDEAL - SHARED WITH YOUTUBE - Reversed so videos can play concurrently.
        return !this.getActiveTask("youTubePlayer", "Full");
      },
    },
    created() {
      // https://github.com/obsproject/obs-browser/blob/master/README.md
      this.obsStudioPluginVersion = window.obsstudio?.pluginVersion;
    },
    mounted() {
      this.videoEl = this.$refs.videoEl;
      this.initPeer();
    },
    beforeDestroy() {
      this.videoEl.stop();
      this.videoEl.srcObject = null;
      this.isScreensharePlaying = false;
      this.peerInstance.disconnect();
      this.peerInstance = null;
    },
    methods: {
      initPeer() {
        this.peerInstance = new Peer(this.clientIdFormatted, {
          // host,
          // path: "/peerjs",
          // port: 443,
          secure: true,
        });

        // ON PEER ERROR
        this.peerInstance.on("error", (error) => {
          // console.error(error);
          this.$log(
            `${this.getDisplayName}:${this.getClientDeviceId} tried to join peer call but saw an error:`,
            error
          );
        });

        // ON CONN
        this.peerInstance.on("connection", (connection) => {
          console.log("Incoming peer connection!");

          // ON CONN DATA
          connection.on("data", (data) => {
            console.log(`Received: ${data}`);
          });

          // ON CONN DATA
          connection.on("open", (id) => {
            connection.send("Connection open! Yay!:", id);
          });

          // ON CONN CLOSE
          connection.on("close", (woo) => {
            if (this.videoEl) {
              this.videoEl.srcObject = null;
              this.isScreensharePlaying = false;
              this.$log(`${this.getDisplayName}:${this.getClientDeviceId} exited the peer call.`);
            }
          });
        });

        // ON CALL
        this.peerInstance.on("call", (call) => {
          const stream = this.createMediaStream();
          call.answer(stream); // Answer the call with an A/V stream.

          call.on("stream", (mediaStream) => {
            this.videoEl.srcObject = mediaStream;
            this.videoEl.play();
            this.isScreensharePlaying = true;
            this.$log(`${this.getDisplayName}:${this.getClientDeviceId} joined the peer call.`);
          });
        });
      },

      createMediaStream() {
        // issue: https://github.com/peers/peerjs/issues/158

        const createEmptyAudioTrack = () => {
          const ctx = new AudioContext();
          const oscillator = ctx.createOscillator();
          const dst = oscillator.connect(ctx.createMediaStreamDestination());
          oscillator.start();
          const track = dst.stream.getAudioTracks()[0];
          return Object.assign(track, { enabled: false });
        };

        const createEmptyVideoTrack = ({ width, height }) => {
          const canvas = Object.assign(document.createElement("canvas"), { width, height });
          canvas.getContext("2d").fillRect(0, 0, width, height);
          const stream = canvas.captureStream();
          const track = stream.getVideoTracks()[0];
          return Object.assign(track, { enabled: false });
        };

        return new MediaStream([
          createEmptyAudioTrack(),
          createEmptyVideoTrack({ width: 640, height: 480 }),
        ]);

        // const CONSTRAINTS = {
        //   // audio: false,
        //   video: {
        //     aspectRatio: 1.777777778,
        //     frameRate: { ideal: 25, max: 25 },
        //     quality: 10,
        //     width: { min: 1280, ideal: 1920, max: 1920 },
        //     height: { min: 720, ideal: 1080 },
        //   },
        // };
      },
    },
  };
</script>

<style scoped lang="scss">
  .screen-share-player-parent {
    display: flex; // [LOCKED]
    align-items: center; // [LOCKED]
    justify-content: center; // [LOCKED]

    position: fixed;
    top: rem-calc(-35); // perfect
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100% - #{rem-calc(80)});
    // border: 1px dashed orange;
    z-index: -2; // plays behind bg (youtubePlayer)
    // z-index: 10;

    &.is-hidden {
      visibility: hidden;
    }

    &.is-mini {
      top: 0;
      height: 100%;
    }
  }

  //---------------------------------------------------------
  // PLAYER OUTER
  // http://jsfiddle.net/DZTvR/13/
  // https://stackoverflow.com/questions/16549919/is-there-a-way-to-make-the-css-property-mask-image-responsive
  // https://stackoverflow.com/questions/29381545/how-to-make-circle-shaped-video-player-on-safari-browser
  //---------------------------------------------------------

  .player-outer {
    width: 100%;
    height: 100%;

    // This allows TV to remain clear when YT is fullscreen.
    mask: url("./assets/img/fullscreen-mask-2.svg") bottom -110px right;
    mask-repeat: no-repeat;
    mask-size: 1920px; // TODO: make this big one day for 1440 screens(?)

    transform-origin: center;
    overflow: hidden;
    will-change: transform;
  }

  .is-mini .player-outer {
    position: absolute;
    bottom: rem-calc(25);
    right: rem-calc(35);
    width: rem-calc(244);
    height: rem-calc(196);
    mask: none;
    transform-origin: bottom right;
    transform: rotate(-15deg);
  }

  .wallpaper-icons {
    z-index: -10;
  }

  .is-mini .wallpaper-icons {
    transform: scale(1.15);
  }

  //---------------------------------------------------------
  // PLAYER INNER
  //---------------------------------------------------------

  .player-inner {
    width: 100%;
    height: 100%;
    // border: 3px dashed blue;
  }

  //---------------------------------------------------------
  // VIDEO OUTER
  //---------------------------------------------------------

  .video-outer {
    display: flex; // [LOCKED]
    align-items: flex-start; // [LOCKED]
    justify-content: flex-end; // [LOCKED]

    position: absolute;
    top: rem-calc(100);
    left: 0;
    width: calc(100% - #{rem-calc(420)});
    height: calc(100% - #{rem-calc(170)});
    padding-right: rem-calc(64);
    // border: 1px dashed orange;
    border-radius: 10px;
    pointer-events: none;
    z-index: 1;

    video {
      max-height: 100%;
      border-radius: 10px;
      // border: 2px solid red;
    }
  }

  // NOTE: this was too buggy in practice.
  // .is-chat-open .video-outer {
  //   align-items: flex-start;
  //   justify-content: flex-end;
  //   width: calc(100% - #{rem-calc(420)});
  //   padding-right: rem-calc(64);
  // }

  .is-playing .video-outer video {
    box-shadow: 0 5px 20px 15px rgba(black, 0.2);
  }

  .is-mini .video-outer {
    justify-content: center;
    top: 0;
    width: 100%;
    height: 100%;
    padding: rem-calc(50);

    video {
      box-shadow: 0 5px 20px 5px rgba(black, 0.5);
    }
  }
</style>
